import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Newsletter Writing Services",
  whatWeOffer: {
    leftPart: "Accumulating a subscriber base is a task that takes time and dedication. A different and equally challenging task is keeping those subscribers. One surefire way of doing this is providing them with a quality newsletter every week.",
    rightPart: "Any newsletter needs to be crisp, short, sweet, to-the-point, eye-catching, and attention-grabbing. It is an excellent medium to offer something new and exciting to your subscribers."
  },
  processText: "At Podroom Creative, our management team will talk to you and determine your buyer personas and plans for the future. Based on that, we can come up with texts for your newsletter. Contact us, and we'll talk more.",
  testimonials: testimonials
}

const meta = {
  title: "Newsletter Writing Services",
  description: "Accumulating a subscriber base is a task that takes time and dedication. A different and equally challenging task is keeping those subscribers. One surefire way of doing this is providing them with a quality newsletter every week."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/newsletter-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)